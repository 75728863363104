@use 'grid' as *;

.Deals__container {
	$gap: map-get(
		$map: $spacers,
		$key: 3,
	);

	@include make-grid($columns: 1fr $gap 1fr $gap 1fr, $rows: auto 1fr);

	@include media-breakpoint-down(sm) {
		@include make-grid($columns: 100%, $rows: auto 0 1fr $gap 1fr $gap 1fr);
	}

	.Deals__title {
		@extend .h3;
		@include place-in-grid(1, 1, $x-span: 5);

		@include media-breakpoint-down(sm) {
			@include place-in-grid(1, 1, $gap: true);
		}
	}
	
	@each $num in (1, 2, 3) {
		.Deals__img-#{$num} {
			@include place-in-grid($num, 2, $gap: true);

				@include media-breakpoint-down(sm) {
					@include place-in-grid(1, $num + 1, $gap: true);
				}
		}
	}
}

.deals-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-right: -15px;
  
  .deal {
    cursor: pointer;
    width: 100%;
    margin-bottom: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width:540px) {
      margin-right:0;
    }

    @media screen and (min-width: 768px) {
      max-width: 400px;
      width: calc(50% - 15px);
    }
    
    @media screen and (min-width: 1200px) {
      max-width:31.5%;
    }

    .deal-valid {
      font-size: 0.85rem;
    }
  }
}
.deal-image {
  border: 4px solid white;
  background: #dddddd;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3C3C3C;
  font-size: 24px;
  font-weight: bolder;
  // height: 200px;
  width: 100%;
  // max-width: 300px;
  text-align: center;
  margin-bottom: 1rem;
  overflow: hidden;

  &.no-image {
    height: 200px;
  }

  img {
    width: 100%;
    height: auto;
  }
} 
