@mixin make-grid($columns, $rows, $columns-repeat: null, $rows-repeat: null) {
	display: -ms-grid;
	display: grid;

	-ms-grid-columns: if($columns-repeat, unquote('(#{$columns})[#{$columns-repeat}]'), $columns);
	-ms-grid-rows: if($rows-repeat, unquote('(#{$rows})[#{$rows-repeat}]'), $rows);

	grid-template-columns: if($columns-repeat, repeat($columns-repeat, $columns), $columns);
	grid-template-rows: if($rows-repeat, repeat($rows-repeat, $rows), $rows);
}

@mixin place-in-grid($x, $y, $x-span: null, $y-span: null, $gap: false) {
	@if $gap {
		$x: ($x * 2) - 1;
		$y: ($y * 2) - 1;

		$x-span: if($x-span, $x-span * 2 - 1, null);
		$y-span: if($y-span, $y-span * 2 - 1, null);
	}
	-ms-grid-column: $x;
	-ms-grid-row: $y;

	@if $x-span {
		-ms-grid-column-span: $x-span;
		grid-column: $x / span $x-span;
	} @else {
		grid-column: $x;
	}

	@if $y-span {
		-ms-grid-row-span: $y-span;
		grid-row: $y / span $y-span;
	} @else {
		grid-row: $y;
	}
}
