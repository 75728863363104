.points {
  display: flex;
  span {
    border-radius: 25px;
    background: black;
    color: $primary;
    padding: 10px 25px;
    margin: 1rem 0;
  }
}

.with-points {
	position: relative;
	display:flex;
	align-items: center;

	.points span {
		padding:8px 25px;
    margin:0;

    @media screen and (max-width: 360px) {
      font-size: 0.8rem;
    }
	}

	.btn-text {
		position: absolute;
		left: 50%;
    transform: translateX(-50%);
    
    @media screen and (max-width: 420px) {
      transform: translateX(0);
    }
	}
}

.btn-container {
  display: flex;
  justify-content: center;
  width: 100%;

  .btn:first-child {
    margin-right:0.5rem;
  }

  .btn:last-child {
    margin-left:0.5rem;
  }
}

.catalogue-heading {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  span {
    width: 100%;
    font-size:18px;
    @media screen and (min-width: 768px) {
      margin-left: auto;
      width: auto;
    }
  }
}