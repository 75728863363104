// @use 'grid' as *;

// .Home__container {
// 	$gap: map-get($map: $spacers, $key: 3);
	
// 	@include make-grid($columns: 1fr $gap 1fr, $rows: 1fr $gap 1fr);

// 	.Home__points {
// 		@include place-in-grid(1, 1, $gap: true);
// 		@extend %action-box;
// 	}
	
// 	.Home__vouchers {
// 		@include place-in-grid(2, 1, $gap: true);
// 		@extend %action-box;
// 	}
	
// 	.Home__deals {
// 		@include place-in-grid(1, 2, $gap: true);
// 		@extend %action-box;
// 	}
	
// 	.Home__transactions {
// 		@include place-in-grid(2, 2, $gap: true);
// 		@extend %action-box;
// 	}

	%action-box {
		@extend .d-flex, .flex-column, .justify-content-center, .align-items-center;
		@extend .p-4;
		background-color: $dark-color;
    text-align: center;
    cursor: pointer;

		.big-text {
			font-size: 3rem;
		}
		
		.highlight-text {
			color: gold;
		}
	}
// }

.home-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: -15px;
  & > div {
    width: 45%;
    margin-right: 15px;
    margin-bottom: 15px;
    font-size: 1rem;
    @extend %action-box;

    @media screen and (max-width: 420px) {
      width: calc(50% - 15px);
      font-size: 0.8rem;

      .big-text {
        font-size:2.8rem;
      }
    }

    @media screen and (max-width: 360px) {
      font-size:0.7rem;
    }
  }
}