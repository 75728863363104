.tablink {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &.active {
    color: #F2DB03;
  }
}

.voucher-container {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 2px solid #252525;
  // font-family: Arial, Helvetica, sans-serif;
  flex-wrap: wrap;
  padding: 15px 0;
  cursor: pointer;
  @media screen and (min-width: 576px) {
    flex-wrap: nowrap;
  }
}
.voucher-detail {
  margin-top: 15px;
  @media screen and (min-width: 576px) {
    margin-left: 15px;
    margin-top: 0;
  }
  .expiry {
    display: flex;
    flex-wrap: wrap;
    font-weight: 400;
  }
}

.voucher-image {
  border: 4px solid white;
  background: #dddddd;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3C3C3C;
  // width: 100%;
  font-weight: bolder;
  font-size: 24px;
  // height: 100px;
  height: 200px;
  width: 300px;
  max-width: 100%;
  overflow: hidden;
  text-align: center;
  img {
    width: 100%;
    height: auto;
  }
  @media screen and (min-width: 576px) {
    // max-width: 300px;
    font-size: 24px;
  }
  @media screen and (max-width: 576px) {
    width:100%;
    height:auto;
  }
}
.voucher-modal {
  .modal-content  {
    background-color: #3C3C3C;
  }
  .close {
    color: #ffffff;
  }

  .voucher-image {
    width:98%;
    height:auto;
  }
}