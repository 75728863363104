@import 'styles/constants';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'styles/layout';
@import 'styles/login';
@import 'styles/homepage';
@import 'styles/deals';
@import 'styles/profile_form';
@import 'styles/my_rewards';
@import 'styles/catalogue';
@import 'styles/forget';
@import 'styles/transaction';

html,
body {
  font-family: 'Eczar', Roboto, Arial, Helvetica, sans-serif;
  font-weight: 400;
	box-sizing: border-box;
	margin: 0;
	min-height: 100vh;

	background-color: #3c3c3c;
	color: white;
}

#app {
	height: 100vh;
}

.label-box {
	border: 1px solid #d9d9d9;
	font-size: 0.8rem;
	background: white;
	color: black;
	&.disabled {
		background: #e9ecef;
	}
}

input.form-control,
textarea.form-control {
	&,
	&:focus {
		border: 0;
		box-shadow: none !important;
		outline: none !important;
	}

	&.bordered {
		border: 1px solid #d9d9d9;
	}
}

.custom-select {
	-webkit-appearance: none;
	appearance: none;
	background: #ffffff url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat center right !important;
  background-size: 19px 38px !important;

	min-height: 64px;
	border-color: gray;
	padding-right: 0;

	&.blend-in {
		background: none;
		min-height: auto;
		border: 0;
		&,
		&:focus {
			outline: 0;
			box-shadow: none !important;
    }
    &.is-invalid {
      border: 1px solid #dc3545;
    }
  }
}

.was-validated .form-control:not(.custom-select):invalid,
.form-control:not(.custom-select).is-invalid {
	background-image: none;
	padding-right: 0;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
	background-image: none;
	padding-right: 0;
}

.cursor-pointer {
	cursor: pointer;
}

.barcode-container {
	background: white;
	@extend .p-3;

	.barcode-label {
		@extend .text-dark, .text-center, .mt-3, .mb-0, .h5;
	}
}

.modal-content  {
  background-color: #3C3C3C;
}
.close {
  color: #ffffff !important;
}
.modal-header {
  border-color: #3C3C3C;
  padding: 1rem 1rem 0rem;
}
.btn {
  cursor: pointer !important;
}

.infoquest {
	position: relative;
}

.question {
	width: 30px;
	height: 30px;
	position: absolute;
	bottom: 103%;
	right: 1%;
}

.tooltiptext {
	width: 50%;
    background-color: #FFC30C;
    color: black; 
    text-align: center;
    border-radius: 6px;
    padding: 10px; 
    left: 105%;
    bottom: -25px;
	position: absolute;
	z-index: 1;
	@media (max-width: 991.98px) {
	    left: 37%;
    	bottom: 4px;
	}
  }

@media only screen and (min-width: 767.98px) {
	.tooltiptext2 {
		visibility: hidden;
		width: 50%;
		background-color: #FFC30C;
		color: black; 
		text-align: center;
		border-radius: 6px;
		padding: 10px; 
		left: 105%;
		bottom: -25px;
		position: absolute;
		z-index: 1;
		@media (max-width: 991.98px) {
		    left: 37%;
    		bottom: 4px;
		}
	}

	.infoquest:hover .tooltiptext2 {
		visibility: visible;
	}

	.question {
		width: 30px;
		height: 30px;
		position: absolute;
		bottom: 4px;
		right: 1%;
	}
}

@media only screen and (max-width: 767.98px) {
	.tooltiptext2 {
		visibility: hidden;
		width: 50%;
		background-color: #FFC30C;
		color: black; 
		text-align: center;
		border-radius: 6px;
		padding: 10px; 
		left: 105%;
		bottom: -25px;
		position: absolute;
		z-index: 1;
		@media (max-width: 991.98px) {
		    left: 37%;
    		bottom: 4px;
		}
	}

	.question {
		width: 30px;
		height: 30px;
		position: absolute;
		bottom: 4px;
		right: 1%;
	}
}

