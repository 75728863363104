.forget-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  // max-width: 768px;
  margin: 0 auto;
  text-align: center;
  padding: 0 15px;
  background-color: #252525;
}

.otp {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  input {
    max-width: 50%;
    height: auto;
  }
  .btn {
    max-width: 50%;
    margin-left: 1rem;
  }
}

.reset-form {
  margin-top: 1rem;
  max-width: 300px;
}