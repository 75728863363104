body.login-darker {
  background-color: #252525
}

.login {
  background-color: #252525;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 1px;
  padding-bottom: 55px;
}

.logo-container {
  width: 100%;
  max-width: 200px;
  img {
    width: 100%;
  }
}

.rewards {
  font-family: 'Eczar', serif;
  font-size: 1.9rem;
}

.login-text {
  font-family: 'Eczar', serif;
  margin: 25px 0;
}

.phone-container {
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  img {
    width: 100%;
  }
}

.mobile-prepend {
  margin-right: 0;
  .input-group-text {
    border:0;
    border-right: 1px solid #252525;
    background: white;
  }
}

.login-mobile, .login-mobile:focus {
  border: 0;
  box-shadow: none;
}

.forgot {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    margin: 0 10px;
  }
}

.signup {
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .signup-form {
    width: 100%;
    max-width: 500px;
    padding: 15px;

    .phone {
      display: flex;
      button {
        border-radius: 0;
        font-weight: bold;
      }
    }

    .birth-gender {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .comm-prefs {
      display: flex;
    }
  }
}
