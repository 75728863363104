@use 'grid' as *;

@each $side in (top, bottom, left, right) {
	.dark-border-#{$side} {
		border-#{$side}: 2px solid $dark-color;
	}
}

.Base__layout {
	height: 100vh;

	@include make-grid($columns: 100%, $rows: auto 1fr);

	.Base__topbar {
		@include place-in-grid(1, 1);
    background: #252525;
    z-index: 900;
	}

	.Base__container {
    @include place-in-grid(1, 2);
    & > .row {
      @media screen and (min-width: 768px) {
        height: 100% !important;
      }
    }
	}
}

.Base__navbar {
	@extend .d-flex, .flex-column, .align-items-start;
	.Base__navlink {
		@extend .btn, .btn-link, .text-light;

		&:not(.active):not(:hover) {
			opacity: 0.5;
		}
	}
}

.menu-toggle {
  cursor: pointer;
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.rewards-title {
  font-size: 1.7rem;
  @media screen and (min-width: 576px) {
    font-size: 2rem;
  }
}

.drawer {
  display: block;
  width: 100%;
  transition: transform 0.3s cubic-bezier(0,1.13,.86,.97);
}

.hidden {
  transform: translateY(-100%);
  height: 0;
  opacity: 0;
}

.alignSpan {
  padding-top: 0.375rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  padding-bottom: 0.375rem;
  span {
    font-size: 1.4rem;
  }
}

.clipClick {
	cursor: pointer;
}

