@use 'grid' as *;

.ProfileForm__grid {
	$x-gap: map-get($map: $spacers, $key: 2);
	$y-gap: map-get($map: $spacers, $key: 4);

	@include make-grid(
		$columns: 1fr $x-gap 1fr $x-gap 1fr $x-gap 1fr $x-gap 1fr $x-gap 1fr, 
		$rows: auto $y-gap,
		$rows-repeat: 7,
  );
  
  &.others {
    @include make-grid(
      $columns: 1fr $x-gap 1fr $x-gap 1fr $x-gap 1fr $x-gap 1fr $x-gap 1fr, 
      $rows: auto $y-gap,
      $rows-repeat: 8,
    );
    .grid-otp {
      @include place-in-grid(1, 3, $x-span: 6, $gap: true);
    }
    .grid-email {
      @include place-in-grid(1, 4, $x-span: 6, $gap: true);
    }
    .grid-bMonth {
      @include place-in-grid(1, 5, $x-span: 2, $gap: true);
    }
  
    .grid-bYear {
      @include place-in-grid(3, 5, $x-span: 2, $gap: true);
    }
  
    .grid-gender {
      @include place-in-grid(5, 5, $x-span: 2, $gap: true);
    }
  
    .grid-area {
      @include place-in-grid(1, 6, $x-span: 6, $gap: true);
    }
  
    .grid-diet {
      @include place-in-grid(1, 7, $x-span: 6, $gap: true);
    }
  
    .grid-know {
      @include place-in-grid(1, 8, $x-span: 6, $gap: true);
    }
  }

	.grid-name {
		@include place-in-grid(1, 1, $x-span: 6, $gap: true);
	}

	.grid-phone {
		@include place-in-grid(1, 2 , $x-span: 3, $gap: true);
	}

	.grid-send-otp {
		@include place-in-grid(4, 2, $x-span: 3, $gap: true);
	}

	.grid-email {
    @include place-in-grid(1, 3, $x-span: 6, $gap: true);
  }
	.grid-bMonth {
		@include place-in-grid(1, 4, $x-span: 2, $gap: true);
	}

	.grid-bYear {
		@include place-in-grid(3, 4, $x-span: 2, $gap: true);
	}

	.grid-gender {
		@include place-in-grid(5, 4, $x-span: 2, $gap: true);
	}

	.grid-area {
		@include place-in-grid(1, 5, $x-span: 6, $gap: true);
	}

	.grid-diet {
		@include place-in-grid(1, 6, $x-span: 6, $gap: true);
	}

	.grid-know {
		@include place-in-grid(1, 7, $x-span: 6, $gap: true);
  }
  
  .disabled {
    .label-box {
      background-color: #dddddd;
    }
    .custom-select {
      background: none !important;
    }
  }
}