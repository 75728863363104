.transaction-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  .transaction {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #595959; 
    margin-bottom: 1.5rem;
    padding: 10px;
    font-weight: 500;
    .transaction-title {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 20px;
      font-weight: 600;
    }
  }
}